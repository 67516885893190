import * as React from "react";
import Layout from "../components/global/Layout";
import Hero from "../components/listed-physician/Hero";
import Form from "../components/listed-physician/Form";
import { useListedPhysicianQuery } from "../hooks/useListedPhysicianQuery";
import Helmet from "react-helmet";

const ListedPhysicianPage = () => {
    const data = useListedPhysicianQuery();
    return (
        <Layout>
            <Hero data={data.listedPhysicianFields} />
            <Form data={data.listedPhysicianFields} />
            <Helmet>
                <title>{data.listedPhysicianMetaData.metaTitle}</title>
                <meta name="description" content={data.listedPhysicianMetaData.metaDescription} />
            </Helmet>
        </Layout>
    );
};

export default ListedPhysicianPage;