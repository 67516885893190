import { useStaticQuery, graphql } from "gatsby"

export const useListedPhysicianQuery = () => {


    const data = useStaticQuery(graphql`
    query ListedPhysicianQuery {
        wpPage(title: {eq: "LISTED PHYSICIAN"}) {
          ACF_ListedPhysicianFields {
            listedPhysicianFields {
                title
                description
                form
            }

            listedPhysicianMetaData{
                metaTitle
                metaDescription
            }
        }
        }
    }     
    `)
    return data.wpPage.ACF_ListedPhysicianFields;
}